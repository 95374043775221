import lock from "./lock.svg"
import styles from "./OTPEnabled.module.css"

function OTPEnabled() {
    return (
        <div className={styles.displayBar}>
            <div className={styles.left}>
            <img src={lock} alt="OTP Enabled" height={35} width={35} />
            </div>
            <div className={styles.right}>
                <span className={styles.label}>One-time passwords enabled</span>
                <span className={styles.description}>You can use one-time password codes generated by this app to verify your sign-ins</span>
            </div>
        </div>
    )
}

export default OTPEnabled